import React, { Component } from 'react';
import { Route, Routes } from 'react-router';
import { Layout } from './components/Layout';
import Home from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import i18n from "./i18n";

import './custom.css'
import { Firmware } from './components/Firmware';
import Register from './components/Register';
import Login from './components/Login';
import Drones from './components/account/Drones';
import Settings from './components/Settings';
import Airmap from './components/Airmap';
import ConfirmEmail from './components/ConfirmEmail';
import Mp from './downloads/Mp';
import Qgc from './downloads/Qgc';
import Alp from './downloads/Alp';
import ForgotPassword from './components/ForgotPassword';
import ForgotPasswordConfirmation from './components/ForgotPasswordConfirmation';
import OnlineDrones from './components/OnlineDrones';
import Users from './components/Users';
import Wiki from './components/wiki/Wiki';
import QuickStart from './components/wiki/QuickStart';
import Ogn from './components/wiki/Ogn';
import Yellcs from './components/trash/Yellcs';
import Statistic from './components/statistics/Statistic';
import ModemStatistic from './components/statistics/ModemStatistic';
import ModemsStatistic from './components/statistics/ModemsStatistic';
import Firmvarev2 from './components/Firmvarev2';
import Console from "./components/console/Console"

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <>
      <Layout>
          <Route exact path='/' element={<Console />}/>
          <Route path='/counter' component={Counter} element={<Counter />}/>
          <Route path='/fetch-data' component={FetchData} element={<FetchData />}/>
          <Route path='/firmware' component={Firmvarev2} element={<Firmvarev2 />}/>
          <Route path='/settings' component={Settings} element={<Settings />}/>
          <Route path='/registration' component={Register} element={<Register />}/>
          <Route path='/login' component={Login} element={<Login />}/>
          <Route path='/forgot-pass' component={ForgotPassword} element={<ForgotPassword />}/>
          <Route path='/forgot-pass-confirm' component={ForgotPasswordConfirmation} element={<ForgotPasswordConfirmation />}/>
          <Route path='/drones' component={Drones} element={<Drones />}/>
          <Route path='/map' component={Airmap} element={<Airmap />}/>
          <Route path='/confirm-email' component={ConfirmEmail} element={<ConfirmEmail />}/>
          <Route path='/mp' component={Mp} element={<Mp />}/>
          <Route path='/qgc' component={Qgc} element={<Qgc />}/>
          <Route path='/alp' component={Alp} element={<Alp />}/>
          <Route path='/onlineDrones' component={OnlineDrones} element={<OnlineDrones />}/>
          <Route path='/users' component={Users} element={<Users />}/>
          <Route path='/wiki' component={Wiki} element={<Wiki />}>
              <Route path="quickStart" element={<QuickStart />} />
              <Route path="ogn" element={<Ogn />} />
          </Route>
          <Route path='/yellcs' component={Yellcs} element={<Yellcs />}/>
          <Route path='/statistics' component={Statistic} element={<Statistic />}>
            {/*<Route path="modem/:imei" element={<ModemStatistic />} />*/}
          </Route>
          <Route path='/statistics/modem' component={ModemStatistic} element={<ModemStatistic />}></Route>
          <Route path='/statistics/modems' component={ModemsStatistic} element={<ModemsStatistic />}></Route>
      </Layout>
      </>

    );
  }
}
