import React, { useState, useEffect } from 'react'
import './ConsoleNavMenu.css';
import * as signalR from "@aspnet/signalr";


function ConsoleNavMenu({modemName, isArm, mode, messages, satellites, batteries, drone, toggleShowMenu, type, showMsgs}){
    const protocol = new signalR.JsonHubProtocol();
    const [hub, setHub] = useState(hubConnect);
    console.log(drone);
    const [imei, setImei] = useState(drone?.imei ?? null);
    const [arm, setArm] = useState(isArm);
    const [uavMode, setUavMode] = useState(drone?.mode);
    const [uavType, setUavType] = useState(type);
    const [hbUpdate, setHbUpdate] = useState(null);
    const [statusText, setStatusText] = useState([]);

    useEffect(() => {
        console.log("ConsoleNavMenu mount");
      }, []);

    useEffect(() => {
        return () => {
            hub.stop();
        };
    }, []);

    useEffect(() => {
        if(hbUpdate != null){
            if(hbUpdate.imei === drone?.imei) {
                setArm(hbUpdate.isArm);
                setUavMode(hbUpdate.mode);
                setUavType(hbUpdate.type);
            }
        } 
    }, [hbUpdate]);

    useEffect(() => {
        console.log("update drone dd");
        if(drone != null) {
            setArm(drone.isArm);
            setUavMode(drone.mode);
            setImei(drone.imei);
        }
        //todo: request api
    }, [drone]);

    function hubConnect(){
        let connection = new signalR.HubConnectionBuilder().withUrl("api/drone-hub").withHubProtocol(protocol).build();

        connection.on('HbStatus', (message) =>
        {
            console.log(message);

            setHbUpdate(message);
        });

        connection.on('StatusText', (message) =>
        {
            console.log(message);

            setStatusText((prevText) => [...prevText, message]);
        });
        connection.start();
        return connection;
    }

    function TelemetryGrid({arm, msg, mode, sat, batt, name}) {
        return(
            <div className='telemetry-grid'>
                <div className='tel-box name-box'>{name}</div>
                <div className='tel-box'>
                    {arm ? 'ARM' : 'DISARM'}
                </div>
                <div className='tel-box msg-box' onClick={showMsgs}>{statusText.findLast((t) => t.imei === imei)?.message}</div>
                <div className='tel-box mode-box'>{mode}</div>
                <div className='tel-box sat-box'><img className='sat-box-icon' src="/image/modem/sat.svg"/><div>20</div></div>
                <div className='tel-box bat-box'>20%</div>
                <div className='tel-box menu-box' onClick={toggleShowMenu}><img className='menu-box-sep' src="/image/modem/menu_line_sep.svg"/><img className='menu-box-icon' src="/image/modem/menu.svg"/></div>
            </div>
        )
    }

    function EmptyGrid() {
        return(
            <div className='empty-grid'>
                <div className='tel-box logo-box'>Air-link Console</div>
                <div className='tel-box menu-box' onClick={toggleShowMenu}><img className='menu-box-sep' src="/image/modem/menu_line_sep.svg"/><img className='menu-box-icon' src="/image/modem/menu.svg"/></div>
            </div>
        )
    }


    return(
        <header>
            <div className='console-nav-menu'>
                { modemName == null ? <EmptyGrid /> : <TelemetryGrid arm = {arm} mode = {uavMode} name = {modemName}/> }
            </div>
        </header>
    )
}

export default ConsoleNavMenu;