import React, { useState, useEffect } from 'react'
import {Form, Button} from "react-bootstrap";
import axios from 'axios';
import * as signalR from "@aspnet/signalr";
import 'leaflet/dist/leaflet.css';


function ModemCard({imei, isOnline, onClick, isActive, modemName, version, update, upgrade}){
    useEffect(() => {
        //console.log("airmap mount");
      }, []);

    useEffect(() => {
        return () => {
            //console.log("airmap unmount");
            //hubDrone.stop();
        };
    }, []);

    function modemVersionName(version) {
        if(version < 2000) {
            return "Telem" 
        }
        if(version < 3000) {
            return "EYE"
        }
    }

    function updateIcon(version, update, upgrade) {
        if(version < 2000) {
            return <></>
        }

        if(upgrade) {
            return <img className='update_icon' src="/image/modem/upgrade.svg" title="Требуется обновление" width="25px"/>
        }
        
        if(update != 0) {
            return <img className='update_icon' src="/image/modem/update.svg" title="Требуется обновление" width="25px"/>
        }

        return <></>
    }

    return(
        <div className={'modem-card body-box' + (isActive ? " active-modem-card" : "") } onClick={onClick}>
            <div className='modem-version-name'>
                <p>{modemName}</p>
                <p className='modem-verion-text'>{modemVersionName(version)}</p>
            </div>
            
            <p>IMEI: {imei}</p>
            <div className='modem-version-name'>
                {isOnline ? <p className='is_online'>Online</p> : <p className='is_offline'>Offline</p>}
                {updateIcon(version, update, upgrade)}
            </div>
            
                
            
        </div>
    )
}

export default ModemCard;
