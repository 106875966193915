import React, { useState, useEffect } from 'react'
import {Form, Button, DropdownButton, Dropdown, ProgressBar, Offcanvas} from "react-bootstrap";
import axios from 'axios';
import * as signalR from "@aspnet/signalr";
import { Container } from 'reactstrap';
import '../account/Drones.css';
import Modem from '../account/Modem';
import ConsoleBindCard from './ConsoleBindCard';
import Bind from '../account/Bind';
import SortCard from '../account/SortCard';



import ConsoleNavMenu from './ConsoleNavMenu';
import ConsoleModemCard from './ConsoleModemCard';
import ConsoleBind from './ConsoleBind';
import './Console.css';
import ConsoleMap from './ConsoleMap';
import StatusText from './StatusText';



function Console(){
    const protocol = new signalR.JsonHubProtocol();
    const transport = signalR.HttpTransportType.WebSockets;
    
    const [hubTest, setHubTest] = useState(hubConnect);

    const [drone, setDrone] = useState(null);
    const [drones, setDrones] = useState([]);
    const [isOnline, setIsOnline] = useState(false);
    const [isConfirmEmail, setIsConfirmEmail] = useState(false)
    const [isNebosvodAuth, setIsNebosvodAuth] = useState(false)
    const [nebosvodAuthLogin, setNebosvodLogin] = useState("")

    const [isLoad, setIsLoad] = useState(false);
    const [isBind, setIsBind] = useState(false);
    const [modemCardOnlineTop, setModemCardOnlineTop] = useState(false);

    const [updateOnlineDroneFromHub, setUpdateDroneFromHub] = useState(null);
    const [updateHbFromHub, setUpdateHbFromHub] = useState(null);

    const [showMsgs, setShowMsgs] = useState(false);

    const [showMenu, setShowMenu] = useState(false);
    const handleCloseMenu = () => {setShowMenu(false); console.log("handle close menu")}
    const toggleShowMenu = () => {setShowMenu((s) => !s); console.log("show menu:"); console.log(showMenu);}

    useEffect(() => {
        console.log("drone mount");
        getDronesInfo()
    }, []);

    useEffect(() => {
        console.log("update online drone hub");
        if(updateOnlineDroneFromHub != null){
            var ddd = drones.map(d => 
                {
                    var temp = Object.assign({}, d);
                    if(updateOnlineDroneFromHub.imei === temp.imei){
                        temp.isOnline = updateOnlineDroneFromHub.isOnline
                    }
                    return temp;
                }
            );
            //console.log(ddd);
            setDrones(ddd);
        } 
    }, [updateOnlineDroneFromHub]);

    useEffect(() => {
        console.log("update hb hub");
        if(updateHbFromHub != null){
            var update = false;
            var ddd = drones.map(d => 
                {
                    
                    var temp = Object.assign({}, d);
                    if(updateHbFromHub.imei === temp.imei){
                        if(updateHbFromHub.isArm != temp.isArm)
                        {
                            //todo: refresh only arm
                            update = true;
                        }
                        temp.isArm = updateHbFromHub.isArm;
                        temp.mode = updateHbFromHub.mode;
                    }
                    /*if(drone?.imei === temp.imei) {
                        setDrone(temp);
                        console.log('Set drone tmp:')
                        console.log(temp);
                    }*/
                    return temp;
                }
            );
            //console.log(ddd);
            if(update === true) {
                setDrones(ddd);
            }
        } 
    }, [updateHbFromHub]);

    useEffect(() => {
        return () => {
            console.log("drone.js unmount");
            hubTest.stop();
        };
    }, []);

    function getDroneInfo(){
        axios.get('api/drone/getModem')
        .then(response => {console.log("drone:"); 
            console.log(response.data); 
            setDrone(response.data); 
            setIsOnline(response.data.isOnline); 
            setIsLoad(true) }
        );
    }

    function getDronesInfo(){
        axios.get('api/drone/getModems')
        .then(response => {
            console.log("drone:"); 
            console.log(response.data);
            setDrones(response.data.modemsInfo);
            setIsConfirmEmail(response.data.isConfirmEmail)
            setIsNebosvodAuth(response.data.isNebosvodAuth)
            setNebosvodLogin(response.data.nebosvodLogin)
            setIsLoad(true);
        })
        .catch(ex => {
            console.log(ex);
            if(ex.response.status === 401) {
                window.location = "/login";
            }
        });
    }

    function hubConnect(){
        let connection = new signalR.HubConnectionBuilder().withUrl("api/drone-hub").withHubProtocol(protocol).build();
        connection.on('SetOnline', (message) => 
        { 
            setUpdateDroneFromHub(message);
        });

        connection.on('HbStatus', (message) =>
        {
            setUpdateHbFromHub(message);
        });

        connection.start();
        return connection;
    }

    function nebosvodLogin(login){
        setIsNebosvodAuth(true)
        setNebosvodLogin(login)
        console.log("nebosvod login: " + login)
    }

    function onlineTopClick() {
        setModemCardOnlineTop(!modemCardOnlineTop)
    }

    return(
        <>
        { drone == null ? <ConsoleNavMenu modemName={null}/> : <ConsoleNavMenu modemName={drone.name} drone={drone} isArm={drone.isArm} mode={drone.mode} toggleShowMenu={() => {console.log("show menu"); toggleShowMenu();}}/>}
        
        <div className='console-blocks'>
            <div className='console-drones'>
                <ConsoleMap currentDrone={drone}/>
                
                <div className='console-modem-cards'>
                    {drones.length > 4 ? <SortCard onlineTopClick={() => { onlineTopClick() }} /> : <></>}
                    {modemCardOnlineTop ?
                        drones.sort((x, y) => x.name.localeCompare(y.name, "pl")).sort(x => x.isOnline ? -1 : 1).map(d => 
                            <ConsoleModemCard imei={d.imei} modemName={d.name} isOnline={d.isOnline} isActive={!(drone == null || d.imei !== drone.imei)} version={d.version} update={d.isLastFirmware} upgrade={d.upgradable} isArm={d.isArm} onClick={() => {setIsBind(false); setDrone(d);}}/>
                        ) 
                        : 
                        drones.map(d => 
                            <ConsoleModemCard imei={d.imei} modemName={d.name} isOnline={d.isOnline} isActive={!(drone == null || d.imei !== drone.imei)} version={d.version} update={d.isLastFirmware} upgrade={d.upgradable} isArm={d.isArm} onClick={() => {setIsBind(false); setDrone(d);} }/>
                        )
                    }
                    <ConsoleBindCard onClick={() => {setIsBind(true); setDrone(null);}}/>
                </div>

                <StatusText drone={drone} />

                {isLoad ? 
                <>
                    {isBind | (drone == null || drone.imei == null) ? 
                    <ConsoleBind/>
                    : 
                    <Offcanvas show={showMenu} onHide={handleCloseMenu} name="test name" placement='end'>
                        <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Настройки</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Modem drone={drone} isConfirmEmail={isConfirmEmail} isNebosvodAuth={isNebosvodAuth} nebosvodAuthLogin={nebosvodAuthLogin} onNebosvodAuth={(login) => { nebosvodLogin(login) }}/>
                        </Offcanvas.Body>
                    </Offcanvas>
                    }
                    
                </>
                :
                <>
                </>
                }
            </div>
        </div>
        </>
    )
}
    

export default Console;
