import React, { useState, useEffect } from 'react'
import './StatusText.css';
import * as signalR from "@aspnet/signalr";


function StatusText({drone}){
    const protocol = new signalR.JsonHubProtocol();
    const [hub, setHub] = useState(hubConnect);
    const [imei, setImei] = useState(drone?.imei ?? null);
    const [statusText, setStatusText] = useState([{imei:'862636053092852', message:'Message 1 test', severity:1, time:'10:20:30'}, {imei:'862636053092852', message:'Message 2 test', severity:1, time:'11:22:30'}]);

    useEffect(() => {
        console.log("StatusText mount");
      }, []);

    useEffect(() => {
        return () => {
            hub.stop();
        };
    }, []);

    useEffect(() => {
        console.log("update drone st");
        if(drone != null) {
            setImei(drone.imei);
        }
        //todo: request api
    }, [drone]);

    function hubConnect(){
        let connection = new signalR.HubConnectionBuilder().withUrl("api/drone-hub").withHubProtocol(protocol).build();

        connection.on('StatusText', (message) =>
        {
            console.log(message);

            setStatusText((prevText) => [...prevText, message]);
        });
        connection.start();
        return connection;
    }

    function Messages() {
        return (
            <>
                {statusText.filter(x => x.imei === imei).toReversed().map(x => <Message text={x.message} time={x.time} severity={x.severity}/>)}
            </>
        )
    }

    function Message({text, time, severity}) {
        return (
            <div className='message'>
                <div className='message-time'>{time}</div>
                <div className='message-text'>{text}</div>
            </div>
        )
    }

    return(
        <>
            {drone == null ? <></> : 
                <div className='status-text'>
                    <Messages />
                </div>
            }
        </>
        
        
    )
}

export default StatusText;