import React, { useState, useEffect } from 'react'
import './ConsoleNavMenu.css';
import * as signalR from "@aspnet/signalr";
import {Point} from "leaflet/dist/leaflet-src.esm";
import RotatedMarker from '../RotatedMarker';
import DroneIcon from "../drone.svg";
import CopterIcon from "./map/copter.svg";
import 'leaflet/dist/leaflet.css';
import L from "leaflet";
import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
    Polygon,
    FeatureGroup,
    useMapEvents,
    useMap,
    useMapEvent, Rectangle, Polyline, Tooltip
  } from 'react-leaflet';

const GPSMapIcon = new L.Icon({
    iconUrl: CopterIcon,
    iconRetinaUrl: CopterIcon,
    iconAnchor: new L.Point(10, 30),
    popupAnchor: new L.Point(0, 0),
    //shadowUrl: HeliIcon,
    //shadowSize: new Point(20, 70),
    //shadowColor: "#000000",
    //shadowAnchor: new L.Point(10, 30),
    backgroundColor: "rgba(0,0,0,0)",
    //image: HelicopterImage,
    iconSize: new Point(40, 40),
});

function ConsoleMap({currentDrone}){
    const protocol = new signalR.JsonHubProtocol();
    const [hub, setHub] = useState(hubConnect);
    const [dronePosition, setDronePosition] = useState(null);

    useEffect(() => {
        console.log("ConsoleNavMenu mount");
      }, []);

    useEffect(() => {
        return () => {
            hub.stop();
        };
    }, []);

    function hubConnect(){
        let connection = new signalR.HubConnectionBuilder().withUrl("api/drone-hub").withHubProtocol(protocol).build();

        connection.on('MapPosition', (message) =>
        {
            setDronePosition(message);
        });
        connection.start();
        return connection;
    }


    return(
        <div
            style={{
            width: "100%",
            height: "100%",
            //height: "auto",
            //minHeight: "100%",
            position: "absolute",
            overflow: "hidden",
            //top: "55px",
            top: "0px",
            left: "0px",
            backgroundColor: "#000000",
            paddingLeft: 0,
            paddingRight: 0,
            zIndex: -1,
            }}
        >
            {/*<div className={'map_container'} style={{height: "100%", width: "100%", zIndex: "0", }}>*/}
            <MapContainer center={[dronePosition ? dronePosition.lat : 51.657273, dronePosition ? dronePosition.lng : 39.205014]} 
                zoom={13} scrollWheelZoom={true} style={{height: "100%", width: "100%"}} zoomControl={false}>
                <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                //url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                url="http://{s}.google.com/vt/lyrs=s,p&x={x}&y={y}&z={z}"
                subdomains={['mt0','mt1','mt2','mt3']}
                />
                <RotatedMarker
                    position={[dronePosition ? dronePosition.lat : 51.657273, dronePosition ? dronePosition.lng : 39.205014]}
                    icon={GPSMapIcon}
                    rotationAngle={dronePosition ? -dronePosition.yaw : 0}
                    style={{backgroundColor: "rgba(0,55,255,0.68)", transform: [{rotate: '50deg'}]}}
                >
                    <Popup>
                    A pretty CSS3 popup. <br/> Easily customizable.
                    </Popup>
                    
                </RotatedMarker>
            </MapContainer>
        </div>
    )
}

export default ConsoleMap;