import React, { Component, Suspense } from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import { Routes } from 'react-router-dom';
import Block from '../blocks/Block';
import i18n from "../i18n";

export class Layout extends Component {
  static displayName = Layout.name;

  componentDidMount() {
    i18n.changeLanguage();
  }

  render () {
    return (
      <div style={{minHeight: "100%"}}>
        <Suspense fallback={<div></div>}>
          {/*<NavMenu />*/}
        </Suspense>
        <Suspense fallback={<Loading />}>
          <Block>
            <Routes>
              {this.props.children}
            </Routes>
          </Block>
        </Suspense>
      </div>
    );
  }
}
function Loading(){
  return <div style={{left: "50%"}}><h2 style={{textAlign: "center"}}>Loading...</h2></div>
}
