import React, {useState} from 'react'
import {Form, Button} from "react-bootstrap";
import axios from 'axios';
import {useTranslation} from "react-i18next";

function Login(){
    const [errorInfo, setErrorInfo] = useState(<div></div>);

    const { t } = useTranslation(['login', 'base']);

    function submitForm(event){
        event.preventDefault()
        console.log(event.target[0].value)
        console.log(event.target[1].value)

        axios.post('api/user/login', { login: event.target[0].value, password: event.target[1].value})
            .then(response => {window.location = "/"})
            .catch((error) => {setErrorInfo(<div style={{color: "red", paddingTop: "10px"}}>{t('login_incorrect')}</div>)});
    }

    return(
        <div className='blocks'>
            <div className='body-box'>
                <h1>{t('Entrance', { ns: "base"})}</h1>
                <Form onSubmit={submitForm}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>{t('address')}</Form.Label>
                        <Form.Control type="email" placeholder={t('address_placeholder')} />
                        <Form.Text className="text-muted">
                        {/*We'll never share your email with anyone else.*/}
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>{t('password')}</Form.Label>
                        <Form.Control type="password" placeholder={t('password_placeholder')} />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        {t('Enter', { ns: "base"})}
                    </Button>
                </Form>
                <div style={{marginTop: "10px"}}>
                    <a href='/forgot-pass'>{t('forgot_pass')}</a>
                </div>
                {errorInfo}
            </div>
        </div>
    )
}

export default Login;
