import React, { useState, useEffect } from 'react'
import {Form, Button} from "react-bootstrap";
import axios from 'axios';
import * as signalR from "@aspnet/signalr";
import 'leaflet/dist/leaflet.css';
import './ConsoleModemCard.css';


function ConsoleModemCard({imei, isOnline, onClick, isActive, modemName, version, update, upgrade, isArm}){
    useEffect(() => {
        //console.log("airmap mount");
      }, []);

    useEffect(() => {
        return () => {
            //console.log("airmap unmount");
            //hubDrone.stop();
        };
    }, []);

    function modemVersionName(version) {
        if(version < 2000) {
            return "Telem" 
        }
        if(version < 3000) {
            return "EYE"
        }
    }

    function updateIcon(version, update, upgrade) {
        if(version < 2000) {
            return <></>
        }

        if(upgrade) {
            return <img className='modem-card-update-icon' src="/image/modem/upgrade.svg" title="Требуется обновление" width="25px"/>
        }
        
        if(update != 0) {
            return <img className='modem-card-update-icon' src="/image/modem/update.svg" title="Требуется обновление" width="25px"/>
        }

        return <></>
    }

    return(
        <div className={'console-modem-card' + (isActive ? " console-active-modem-card" : "") } onClick={onClick}>
            <div className='console-modem-card-content'>
                <div>
                    <div className='modem-card-name'>{modemName}</div>
                    <div className='modem-card-imei'>{imei}</div>
                    <div className='modem-card-arm'>{isArm ? 'ARM' : 'DISARM'}</div>
                </div>
                
                <div className='online-update'>
                    {updateIcon(version, update, upgrade)}
                    {isOnline ? <img className='modem-card-online-icon' src="/image/modem/online.svg" title="Online"/> : <img className='modem-card-online-icon' src="/image/modem/offline.svg" title="Offline"/>}
                </div>
            </div>
        </div>
    )
}

export default ConsoleModemCard;
