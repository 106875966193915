import React, { useState, useEffect } from 'react'
import {Form, Button} from "react-bootstrap";
import axios from 'axios';
import * as signalR from "@aspnet/signalr";
import 'leaflet/dist/leaflet.css';


function ConsoleBindCard({onClick}){

    useEffect(() => {
        //console.log("airmap mount");
      }, []);

    useEffect(() => {
        return () => {
            //console.log("airmap unmount");
            //hubDrone.stop();
        };
    }, []);

    return(
        <div className='console-bind-card' onClick={onClick}>
            <img className='bind-card-icon' src="/image/modem/bind_card_icon.svg" title="Требуется обновление" width="25px"/>
        </div>
    )
}

export default ConsoleBindCard;
